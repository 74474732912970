import styled from 'styled-components';
import { Dialog } from '@material-ui/core';
import { shade } from 'polished';

export const Container = styled(Dialog)`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;

  ul {
    border-radius: 5px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    padding: 0 10px;
    overflow-y: auto;
    width: 100%;
    li {
      background: #fff;
      padding: 15px 10px;
      border-radius: 5px;
      margin: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        height: 92px;
        width: 92px;
      }
      .info-container {
        font-size: 16px;
        margin-left: 5px;
        flex: 1;
        p {
          color: #ac0000;
        }
        .product-name {
          font-size: 16px;
          color: #000;
        }
        #info {
          font-size: 12px;
          color: #a0a0b3;
          margin-top: 8px;
        }
        .total-container {
          display: flex;
          p {
            font-weight: bold;
            margin-top: 5px;

            font-size: 16px;
            color: #e83f5b;
          }
          .quantity {
            margin-right: 10px;
          }
        }
      }
    }
  }
  .cart-total {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    color: #ac0000;

    h2 {
      font-size: 1rem;
      margin: 0 0.5rem;
    }
    p {
      color: #000;
      font-size: 1.3rem;
      margin: 0 0.5rem;
    }
  }
  button {
    width: 100%;
    padding: 0.5rem;
    color: white;
    margin-top: 1rem;
    background: #ac0000;
    &:hover {
      background: ${shade(0.2, '#ac0000')};
    }
  }
`;
