import React, { useCallback } from 'react';
import { IoMdPower } from 'react-icons/io';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import { StyledMenu } from './styled';

const Menu: React.FC<{
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ open, setOpen }) => {
  const history = useHistory();
  const { signOut, user } = useAuth();

  const closeApp = useCallback(() => {
    const lenght = history.length + 2;
    history.go(-lenght);
  }, [history]);

  return (
    <StyledMenu open={open} onClick={() => setOpen(!open)}>
      <div className="greattings">
        <p>Olá {user.name}</p>
        <button
          type="button"
          onClick={() => {
            signOut();
            history.push('/signin');
          }}
          style={{ background: 'transparent', color: 'black' }}
        >
          <IoMdPower size="1.2rem" color="#ac0000" />
        </button>
      </div>
      <a href="/">Home</a>
      <a href="/products-list">Lista de Produtos</a>
      <a href="/cart">Carrinho</a>
      <a href="/purchase">Meu Histórico</a>
      {/* <button type="button" onClick={closeApp}>
        Fechar
      </button> */}
    </StyledMenu>
  );
};
export default Menu;
