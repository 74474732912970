import styled from 'styled-components';
import HeaderImg from '../../../assets/header.png';

export const Container = styled.div`
  width: 100%;
  background: #f0f0f5;
  header {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 125px;
    background: url(${HeaderImg});
    background-repeat: no-repeat;
    background-size: cover;
  }

  #product-bar {
    width: 100%;
    background: #ac0000;
    margin: 0;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
      background: yellow;
      color: black;
      padding: 0.5rem;
      border-radius: 5px;
      border: 0;
    }
  }

  main {
    flex: 1;
    overflow-y: auto;

    h1 {
      color: gray;
      font-size: 1.3rem;
      padding: 1rem 1.5rem;
    }

    .my-card {
      width: 90%;
      padding: 1.2rem;
      display: flex;
      margin: 0 auto;
      align-items: center;
      justify-content: space-between;
      background: white;
      -webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.55);
      -moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.55);
      box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.55);
      button {
        border: 1px solid gray;
        border-radius: 3px;
        color: #ac0000;
        background: white;
        padding: 0.5rem;
      }
    }

    .promo {
      width: 90%;
      margin: 1rem;
      -webkit-box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.75);
      box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.75);
    }
  }

  .install-button {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: #ac0000;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      border-radius: 5px;
      color: black;
      background: yellow;
      padding: 0.5rem;
    }
  }
`;
