import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { isAfter } from 'date-fns';
import { BrowserView } from 'react-device-detect';
import * as _ from 'loadsh';
import {
  FormControlLabel,
  Radio,
  Checkbox,
  TextField,
  Button,
} from '@material-ui/core';
import axios from 'axios';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';
import { cpfMask } from '../../utils/masks';
import { Alert, AlertProps } from '../../components/Alert';
import { Container, DeliveryGroup } from './style';
import { useCart } from '../../hooks/cart';
import formatValue from '../../utils/formatValue';
import { useToast } from '../../hooks/ToastContext';
import AdressForm from '../../components/AddressForm';
import validateCpf from '../../utils/validateCPF';
import MaterialAlert from '../../components/MaterialAlert';
import HeaderWeb from '../../components/HeaderWeb';

interface AddressFormData {
  cep: string;
  street: string;
  number: string;
  more: string;
  phone: string;
}

interface Address {
  id: string;
  cep: string;
  street: string;
  number: number;
  more: string;
  phone: string;
  frete: string;
  updated_at: string;
  created_at: string;
}

const Checkout: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { subtotal, isPromoCart, title } = location.state as {
    subtotal: number;
    isPromoCart: boolean;
    title: string | undefined;
  };
  const { addToast } = useToast();
  const { cart, loadCart } = useCart();
  const { signOut, user } = useAuth();

  const [alert, setAlert] = useState<Omit<AlertProps, 'onClose'>>({
    open: false,
    title: '',
    description: '',
  });
  const [deliveryMethod, setDeliveryMethod] = React.useState('delivery');
  const [choosenAddress, setChoosenAddress] = useState<AddressFormData>();
  const [frete, setFrete] = useState(6);
  const [paymentMethod, setPaymentMethod] = useState('card');
  const [change, setChange] = useState(0);
  const [notaFiscal, setNotaFiscal] = useState(false);
  const [cpf, setCpf] = useState<string>('');
  const [requestEnabled, setRequestEnabled] = useState(true);
  const [bestTimeForDelivery, setBestTimeForDelivery] = useState('afternoon');

  const [materialAlert, setMaterialAlert] = useState<{
    open: boolean;
    title: string;
    description: string;
    agree?: string;
    disagree?: string;
    onClose: (val: boolean) => void;
  }>({
    open: false,
    title: '',
    description: '',
    onClose: () => {
      ('');
    },
  });

  useEffect(() => {
    if (user.cpf) setCpf(user.cpf);
  }, [user.cpf]);

  useEffect(() => {
    const axiosOne = api.get('/frete');
    const axiosTwo = api.get('/freteZeroValue');
    axios
      .all([axiosOne, axiosTwo])
      .then(
        axios.spread((...responses) => {
          if (subtotal >= Number(responses[1].data)) setFrete(0);
          else setFrete(Number(responses[0].data));
        })
      )
      .catch((err) => {
        addToast({
          type: 'error',
          title: 'Erro no carregamento de dados',
          description: `Não conseguimos carregar seus dados corretamente. Tente novamente.\n${err}`,
        });
      });
  }, [addToast, subtotal]);

  const handleAlertClose = useCallback(() => {
    setAlert({ ...alert, open: false });
  }, [alert]);

  const handleCloseConfirmationOrder = useCallback(
    (val: boolean): void => {
      if (val) {
        loadCart([]);
        history.push('/store');
      }
    },
    [history, loadCart]
  );

  const SendOrderToServer = useCallback(async () => {
    if (cpf && !validateCpf(cpf)) {
      setAlert({
        open: true,
        title: 'CPF não pode ser validado',
        description: 'Confirme se o CPF informado está correto.',
        buttons: { confirmation: { title: 'OK' } },
      });
    } else {
      if (!user.cpf && cpf) {
        try {
          await api.patch('/users/cpf', { cpf }).catch((err) => {
            addToast({
              type: 'error',
              title: 'CPF informado incorreto',
              description: err.response.data.message,
            });
          });
        } catch (err) {
          addToast({
            type: 'error',
            title: 'Não foi possível adicionar seu CPF',
            description: 'Por favor, tente novamente.',
          });
        }
      }
      try {
        api
          .post('/orders', {
            cartItens: cart,
            subtotal,
            frete,
            choosenAddress,
            paymentMethod,
            change,
            notaFiscal,
            cpf,
            isPromoCart,
            deliveryPeriod: bestTimeForDelivery,
            title,
          })
          .then(() => {
            setMaterialAlert({
              open: true,
              description: `    Parabéns, você acaba de adquirir o melhor da Santa Carne! ${
                deliveryMethod === 'delivery'
                  ? 'Seu pedido será processado e entregue em até 24 horas.'
                  : 'Você escolher retirar seu pedido na cede da Santa Carne, será um prazer te receber na Rua São João, 2100 entre 8:00 e 18:00 de amanhã.'
              }\n\n    O pagamento será realizado no momento da entrega.`,
              title: 'Pedido realizado com sucesso!',
              agree: 'OK',
              onClose: handleCloseConfirmationOrder,
            });
          })
          .catch(() => {
            addToast({
              type: 'error',
              title: 'Não foi possível concluir seu pedido',
              description: 'Por favor, tente novamente.',
            });
          });
      } catch (err) {
        signOut();
        addToast({
          type: 'error',
          title: 'Não foi possível concluir seu pedido',
          description: 'Por favor, tente novamente.',
        });
      }
    }
  }, [
    addToast,
    bestTimeForDelivery,
    cart,
    change,
    choosenAddress,
    cpf,
    deliveryMethod,
    frete,
    handleCloseConfirmationOrder,
    isPromoCart,
    notaFiscal,
    paymentMethod,
    signOut,
    subtotal,
    title,
    user.cpf,
  ]);

  useEffect(() => {
    if (
      (choosenAddress &&
        (deliveryMethod === 'delivery' || deliveryMethod === 'express')) ||
      deliveryMethod === 'local'
    ) {
      if (cpf?.length === 14) {
        setRequestEnabled(false);
        return;
      }
    }
    setRequestEnabled(true);
  }, [choosenAddress, cpf, deliveryMethod]);

  const DeliveryTime: React.FC = () => {
    switch (deliveryMethod) {
      case 'express':
        return <p>Seu produto será entregur hoje até as 18:00h</p>;
      case 'delivery':
        if (isAfter(new Date(), new Date().setHours(16, 0, 0, 0)))
          return <p>Seu produto será entregue amanhã entre 12:00 e 18:00.</p>;
        return (
          <>
            <p className="title">Melhor momento para entrega:</p>
            <DeliveryGroup
              name="time-method"
              value={bestTimeForDelivery}
              onChange={(value) => {
                setBestTimeForDelivery(value.target.value);
              }}
            >
              <FormControlLabel
                value="morning"
                control={<Radio style={{ color: '#ac0000' }} />}
                label="Manhã"
                disabled={isAfter(new Date(), new Date().setHours(12, 0, 0, 0))}
              />
              <FormControlLabel
                value="afternoon"
                control={<Radio style={{ color: '#ac0000' }} />}
                label="Tarde"
              />
            </DeliveryGroup>
          </>
        );
      default:
        return <></>;
    }
  };

  const handleSetChoosenAddress = useCallback(
    (val: Address) => {
      setChoosenAddress(_.omit(val, ['frete']));
      if (deliveryMethod === 'express') setFrete(14.9);
      else if (subtotal >= 125.0) setFrete(0);
      else setFrete(parseFloat(val.frete));
    },
    [deliveryMethod, subtotal]
  );

  return (
    <>
      <BrowserView>
        <HeaderWeb />
      </BrowserView>
      <Container>
        <MaterialAlert
          open={materialAlert.open}
          onClose={materialAlert.onClose}
          title={materialAlert.title}
          description={materialAlert.description}
          agree={materialAlert.agree}
          disagree={materialAlert.disagree}
        />
        <Alert
          open={alert.open}
          onClose={handleAlertClose}
          title={alert.title}
          description={alert.description}
        />
        <p className="title">Método de entrega</p>
        <p className="title brute-value">
          Valor Bruto: <span>{formatValue(subtotal)}</span>
        </p>
        <DeliveryGroup
          name="delivery-method"
          value={deliveryMethod}
          onChange={(value) => {
            setDeliveryMethod(value.target.value);
          }}
        >
          <FormControlLabel
            value="delivery"
            control={<Radio style={{ color: '#ac0000' }} />}
            label="Normal"
          />
          <FormControlLabel
            value="express"
            control={<Radio style={{ color: '#ac0000' }} />}
            label="Express"
            disabled={isAfter(new Date(), new Date().setHours(11, 0, 0, 0))}
          />
        </DeliveryGroup>
        <DeliveryTime />

        <p className="title">Confirmar Endereço</p>
        <AdressForm confirmation={handleSetChoosenAddress} />

        {choosenAddress && (
          <div className="total">
            <p className="title">Frete: </p>
            <p className="value">{formatValue(frete)}</p>
          </div>
        )}

        <div className="total">
          <p className="title">Valor: </p>
          <p className="value">{formatValue(frete + subtotal)}</p>
        </div>

        <p className="title">Forma de pagamento:</p>
        <DeliveryGroup
          name="payment-method"
          value={paymentMethod}
          onChange={(value) => {
            setPaymentMethod(value.target.value);
          }}
        >
          <FormControlLabel
            value="card"
            control={<Radio style={{ color: '#ac0000' }} />}
            label="Cartão"
          />
          <FormControlLabel
            value="money"
            control={<Radio style={{ color: '#ac0000' }} />}
            label="Dinheiro"
          />
        </DeliveryGroup>

        {paymentMethod === 'money' && (
          <TextField
            style={{ margin: '10px 0', width: '100%' }}
            variant="outlined"
            name="change"
            type="number"
            onChange={(event: { target: { value: string } }) => {
              setChange(parseFloat(event.target.value));
            }}
            label="Troco para"
          />
        )}

        <div className="nota">
          <p className="title">Nota Paraná:</p>{' '}
          <Checkbox
            checked={notaFiscal}
            onChange={() => {
              setNotaFiscal(!notaFiscal);
            }}
          />
        </div>

        <TextField
          style={{ margin: '10px 0', width: '100%' }}
          variant="outlined"
          name="cpf"
          type="text"
          label="CPF"
          value={cpf || ''}
          onChange={(event: { target: { value: string } }) => {
            setCpf(cpfMask(event.target.value));
          }}
        />

        <Button
          style={{ width: '100%', padding: 12 }}
          variant="contained"
          color="primary"
          type="button"
          onClick={SendOrderToServer}
          disabled={requestEnabled}
        >
          Realizar Pedido
        </Button>
      </Container>
    </>
  );
};

export default Checkout;
