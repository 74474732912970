import React, { useCallback, useRef, useState } from 'react';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { FiLock, FiMail } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { BrowserView } from 'react-device-detect';
import getValidationErrors from '../../utils/getValidationErrors';
import Input from '../../components/Input';
import { useAuth } from '../../hooks/auth';

import logoImg from '../../assets/logo1.png';

import Button from '../../components/Button';
import { Container } from './style';
import HeaderWeb from '../../components/HeaderWeb';
import Footer from '../../components/Footer';
import { useToast } from '../../hooks/ToastContext';

interface SignInFormData {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { signIn } = useAuth();
  const [apiLoading, setApiLoading] = useState(false);
  const history = useHistory();
  const { addToast } = useToast();

  const handleSignIn = useCallback(
    async (data: SignInFormData) => {
      setApiLoading(true);
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await signIn({
          email: data.email,
          password: data.password,
        });
        setApiLoading(false);
        history.push('/store');
      } catch (err) {
        setApiLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
        addToast({
          type: 'error',
          title: 'E-mail ou senha incorretos',
          description: err.response.data.message,
        });
      }
    },
    [history, signIn, addToast]
  );

  return (
    <>
      <BrowserView>
        <HeaderWeb />
      </BrowserView>
      <Container>
        <img src={logoImg} alt="Santa Carne Logo" className="logo" />
        <h1>Faça seu Login</h1>
        <Form ref={formRef} onSubmit={handleSignIn}>
          <Input name="email" type="text" placeholder="E-mail" icon={FiMail} />
          <Input
            name="password"
            type="password"
            placeholder="Senha"
            icon={FiLock}
          />
          <Button type="submit" loading={apiLoading}>
            Entrar
          </Button>
          <Link to="/forgot" id="forgot-password">
            Esqueci minha senha
          </Link>
        </Form>
        <Link to="/newaccount" id="create-account">
          Criar conta
        </Link>
      </Container>
      <BrowserView style={{ marginTop: '1rem' }}>
        <Footer />
      </BrowserView>
    </>
  );
};

export default SignIn;
