import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { IoMdPower } from 'react-icons/io';
import SearchBar from 'material-ui-search-bar';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { BiMinus, BiPlus } from 'react-icons/bi';
import { FaShoppingCart } from 'react-icons/fa';
import { FiPlus, FiMinus } from 'react-icons/fi';
import { Editor, EditorState, convertFromRaw } from 'draft-js';
import { Container, NavBar, CategoryButton } from './style';
import LogoImg from '../../../assets/whitesanta.png';
import api from '../../../services/api';
import { Product, CartItem, Cut } from '../../../interfaces/CartStructure';
import formatValue from '../../../utils/formatValue';
import { useAuth } from '../../../hooks/auth';
import formatUnits from '../../../utils/formatUnits';
import { useCart } from '../../../hooks/cart';
import WebPromoDialog from '../../../components/WebPromoDialog';
import FloatingCart from '../../../components/FloatingCart';
import { useToast } from '../../../hooks/ToastContext';
import HeaderWeb from '../../../components/HeaderWeb';
import { Alert, AlertProps } from '../../../components/Alert';

interface Category {
  id: string;
  name: string;
  imageUrl: string;
  products: Product[];
}

export interface CartCard {
  id?: string;
  title?: string;
  cartItens: CartItem[];
  cartTotal?: number;
  imageUrl?: string;
}

const Web: React.FC = () => {
  const [promoCarts, setPromoCarts] = useState<CartCard[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<Product[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [search, setSearch] = useState('');
  const { addToCart } = useCart();
  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState(
    selectedProduct ? selectedProduct.price / 2 : 0
  );
  const [cut, setCut] = useState<Cut | undefined>(undefined);
  const [productsToShow, setProductsToShow] = useState<Product[]>(
    selectedCategory
  );
  const { addToast } = useToast();

  const [selectedPromo, setSelectedPromo] = useState<{
    open: boolean;
    promoCart?: CartCard;
  }>({ open: false });

  const [alert, setAlert] = useState<Omit<AlertProps, 'onClose'>>({
    open: false,
    title: '',
    description: '',
  });

  const { signOut, user } = useAuth();

  const getProductDescription = useCallback((data: string) => {
    try {
      JSON.parse(data);
      return (
        <Editor
          editorState={EditorState.createWithContent(
            convertFromRaw(JSON.parse(data))
          )}
          readOnly
          onChange={() => {
            // todo
          }}
        />
      );
    } catch {
      return <p className="description">{data}</p>;
    }
  }, []);

  useEffect(() => {
    api
      .get('/cart/promo-cart')
      .then((response) => {
        setPromoCarts(response.data);
      })
      .catch(() => {
        signOut();
      });
  }, [signOut]);

  const setFilteredCategories = useMemo(() => {
    categories.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
    return categories;
  }, [categories]);

  useEffect(() => {
    try {
      api.get('/categories').then((response) => {
        const res = response.data as Category[];
        setCategories(res);
        if (!response.data[0]) return;
        const { products } = response.data[0];
        setSelectedCategory(products);
        setSelectedProduct(products[0]);
        setPrice(products[0].price);
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Não foi possível carregar as categorias',
        description: error.response.data.message,
      });
    }
  }, [addToast]);

  useEffect(() => {
    setProductsToShow(
      selectedCategory.filter(
        (item) =>
          item.name.toLowerCase().includes(search.toLowerCase()) ||
          (item.cuts &&
            item.cuts.filter((i) =>
              i.name.toLowerCase().includes(search.toLowerCase())
            ).length > 0)
      )
    );
  }, [search, selectedCategory]);

  function handleSelectCategory(id: string): void {
    const products = categories.find((category) => category.id === id)
      ?.products;

    if (!products) setSelectedCategory([]);
    else {
      setSelectedCategory(products);
      setSelectedProduct(products[0]);
      setPrice(products[0].price);
    }
  }

  const showProductDetail = useCallback((product: Product) => {
    setCut(product.cuts ? product.cuts[0] : undefined);
    setSelectedProduct(product);
    setPrice(product.price);
    setQuantity(1);
  }, []);

  const handleAddToCart = useCallback(
    (item: Product) => {
      if (item.cuts !== undefined && !cut) {
        setCut(item.cuts[0]);
      }
      addToCart(item, quantity, cut);
      setQuantity(1);
      setPrice(0);
    },
    [addToCart, cut, quantity]
  );

  const handleSubtract = useCallback(() => {
    if (!selectedProduct) return;
    let new_quantity = quantity;
    if (selectedProduct.category.type === 'itens') {
      if (quantity <= 1) return;
      new_quantity = quantity - 1;
    } else {
      if (quantity <= 0.5) return;
      new_quantity = quantity - 0.5;
    }
    setQuantity(new_quantity);
    setPrice(new_quantity * selectedProduct.price);
  }, [quantity, selectedProduct]);

  const handleAdd = useCallback(() => {
    if (!selectedProduct) return;
    let new_quantity = quantity;
    if (selectedProduct.category.type === 'itens') new_quantity = quantity + 1;
    else new_quantity = quantity + 0.5;
    setQuantity(new_quantity);
    setPrice(new_quantity * selectedProduct.price);
  }, [quantity, selectedProduct]);

  const { increment, decrement, cart } = useCart();

  function handleIncrement(id: string): void {
    increment(id);
  }

  function handleDecrement(id: string): void {
    decrement(id);
  }

  const location = useLocation();
  const state = location.state as
    | {
        title: string;
        total: number;
      }
    | undefined;
  const history = useHistory();
  const cartTotal = useMemo(() => {
    if (cart) {
      console.log(cart);
      const total = cart.reduce(
        (accumulator: number, curr) =>
          accumulator + curr.product.price * curr.quantity,
        0
      );
      return total;
    }
    return 0;
  }, [cart]);

  const handleNavigateToCheckout = useCallback(() => {
    history.push({
      pathname: '/checkout',
      state: {
        subtotal: state?.total || cartTotal,
        isPromoCart: !!state,
        title: state?.title || undefined,
      },
    });
  }, [cartTotal, history, state]);

  useEffect(() => {
    setProductsToShow(
      selectedCategory.filter(
        (item) =>
          item.name.toLowerCase().includes(search.toLowerCase()) ||
          (item.cuts &&
            item.cuts.filter((i) =>
              i.name.toLowerCase().includes(search.toLowerCase())
            ).length > 0)
      )
    );
  }, [search, selectedCategory]);

  const closeAlert = useCallback(
    (response: boolean) => {
      if (response && alert.variable && selectedProduct)
        setCut(
          selectedProduct.cuts?.find((item) => item.name === alert.variable)
        );
      setAlert((prev) => ({ ...prev, open: false }));
    },
    [alert.variable, selectedProduct]
  );

  const sortedCarts = useMemo(() => {
    return promoCarts.sort((a, b) => {
      if (a.title && b.title) return a.title > b.title ? 1 : -1;
      return -1;
    });
  }, [promoCarts]);

  return (
    <Container>
      <Alert
        open={alert.open}
        title={alert.title}
        description={alert?.description}
        onClose={closeAlert}
      />
      <WebPromoDialog
        open={selectedPromo.open}
        handleClose={() => {
          setSelectedPromo({ ...selectedPromo, open: false });
        }}
        promoCart={selectedPromo.promoCart}
      />
      <div className="logo">
        <img src={LogoImg} alt="Santa Carne" />
      </div>
      <NavBar>
        <div className="hi">Olá, {user.name}</div>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/store">Loja</Link>
          </li>
          <li>
            <Link to="/contact">Contato</Link>
          </li>
          <li>
            <Link to="/purchase">Meu Histórico</Link>
          </li>
          <li>
            <button
              type="button"
              onClick={() => {
                signOut();
                history.push('/signin');
              }}
              style={{ background: 'transparent', color: 'white' }}
            >
              <IoMdPower />
            </button>
          </li>
        </ul>
      </NavBar>
      <div className="categories">
        {setFilteredCategories.map((cat) => (
          <CategoryButton
            type="button"
            img={cat.imageUrl}
            key={cat.id}
            isSelected={cat.products === selectedCategory}
            onClick={() => handleSelectCategory(cat.id)}
          />
        ))}
      </div>

      <div className="promos">
        <ul>
          {sortedCarts.map((promo) => (
            <li>
              <button
                type="button"
                onClick={() => {
                  setSelectedPromo({ open: true, promoCart: promo });
                }}
              >
                <img
                  src={promo.imageUrl}
                  alt={promo.title}
                  className="promo"
                  key={promo.id}
                />
              </button>
            </li>
          ))}
        </ul>
      </div>

      <div className="products">
        <h2 className="section-title">Produtos</h2>
        <SearchBar
          style={{ marginBottom: '1rem' }}
          onChange={(newValue) => setSearch(newValue)}
        />
        <div className="products-list">
          {productsToShow.map((item) => (
            <button
              type="button"
              className="product-card"
              key={item.id}
              onClick={() => {
                showProductDetail(item);
              }}
            >
              <img src={item.imageUrl} alt={item.name} />
              <div className="info">
                <h2>{item.name}</h2>
                <span>{formatValue(item.price)}</span>
              </div>
            </button>
          ))}
        </div>
      </div>

      {selectedProduct && (
        <div className="prod-detail">
          <div className="prod-content">
            <h2 className="section-title">Adicionar Produto</h2>
            <div className="inner-container">
              <section className="first-section">
                <div className="imagem">
                  <img
                    src={selectedProduct.imageUrl}
                    alt={selectedProduct.name}
                  />
                  <span className="disclamer">
                    *imagens meramente ilustrativas
                  </span>
                </div>
                <div className="content">
                  <h1>{selectedProduct.name}</h1>
                  <p className="price">
                    {selectedProduct.category.type === 'itens'
                      ? `${formatValue(selectedProduct.price)} / und`
                      : `${formatValue(selectedProduct.price)} / Kg`}
                  </p>
                  <h3>Quantidade</h3>
                  <div className="increment-container">
                    <button type="button" onClick={handleSubtract}>
                      <BiMinus />
                    </button>
                    <p className="product-amount">
                      {selectedProduct.category.type === 'itens'
                        ? `${formatUnits(quantity)} und`
                        : `${formatUnits(quantity)} Kg`}
                    </p>
                    <div className="increment-container">
                      <button type="button" onClick={handleAdd}>
                        <BiPlus />
                      </button>
                    </div>
                  </div>
                  <div className="price-container">
                    <h3>Valor</h3>
                    <p>{formatValue(price)}</p>
                  </div>
                </div>
              </section>
              <main>
                <div className="descrip">
                  {selectedProduct.category.type !== 'itens' &&
                    (!selectedProduct.cuts ||
                      selectedProduct.cuts.length > 0) && (
                      <>
                        <h3>Cortes</h3>
                        <select
                          id="cuts"
                          name="cuts"
                          value={cut?.name}
                          onChange={(event) => {
                            if (event.currentTarget.value === 'peça') {
                              setAlert({
                                open: true,
                                title: 'ATENÇÃO',
                                description:
                                  'A peça inteira pode variar em peso e, por tanto, variar em preço. Logo, o preço exibido no cálculo irá distinguir do preço cobrado no ato da entrega.',
                                buttons: {
                                  confirmation: { title: 'Confirmar' },
                                  rejection: { title: 'Cancelar' },
                                },
                                variable: event.currentTarget.value,
                              });
                            }
                            setCut(
                              selectedProduct.cuts?.find(
                                (item) =>
                                  item.name === event.currentTarget.value
                              )
                            );
                          }}
                        >
                          {selectedProduct.cuts?.map((cuty) => (
                            <option key={cuty.name} value={cuty.name}>
                              {cuty.name}
                            </option>
                          ))}
                        </select>
                      </>
                    )}
                  <h3>Descrição</h3>

                  {getProductDescription(selectedProduct.description)}
                </div>
                <button
                  type="button"
                  onClick={() => handleAddToCart(selectedProduct)}
                >
                  Adicionar ao carrinho
                </button>
              </main>
            </div>
          </div>
        </div>
      )}

      <div className="cart">
        <div className="cart-content">
          <h2 className="section-title">Carrinho de compras</h2>
          <FloatingCart />
          {cart.length > 0 && (
            <>
              <ul>
                {cart.map((item) => (
                  <li key={item.id}>
                    <img src={item.product.imageUrl} alt={item.product.name} />
                    <div className="info-container">
                      <p className="product-name">{item.product.name}</p>

                      <p id="info">
                        {item.product.cuts &&
                          item.product.cuts.length > 0 &&
                          !!item.cut &&
                          item.cut.name}
                      </p>
                      <p id="info">{formatValue(item.product.price)}</p>
                      <div className="price-container">
                        <div className="total-container">
                          <p className="quantity">
                            {item.product.category.type === 'itens'
                              ? `${item.quantity}und -`
                              : `${formatUnits(item.quantity)}Kg -`}
                          </p>
                          <p>
                            {formatValue(item.product.price * item.quantity)}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="actionContainer">
                      <button
                        type="button"
                        className="action-button"
                        id={`increment-${item.id}`}
                        onClick={() => handleIncrement(item.id)}
                      >
                        <FiPlus />
                      </button>
                      <button
                        type="button"
                        className="action-button"
                        id={`decrement-${item.id}`}
                        onClick={() => handleDecrement(item.id)}
                      >
                        <FiMinus />
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
              <div className="cart-total">
                <FaShoppingCart />
                <h2>Total:</h2>
                <p>{formatValue(state?.total || cartTotal)}</p>
              </div>
              <button type="button" onClick={handleNavigateToCheckout}>
                Finalizar Pedido
              </button>
            </>
          )}
        </div>
      </div>
    </Container>
  );
};

export default Web;
