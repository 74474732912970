import { shade } from 'polished';
import styled from 'styled-components';

export const ViewContentContainer = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    max-width: 500px;
    text-align: left;
    font-size: 20px;

    height: 30px;
    border-bottom: 2px solid #ac0000;
  }
  @media (max-width: 700px) {
    width: 100%;
  }
`;

export const ViewContent = styled.div`
  max-width: 500px;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

export const ViewProduct = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;

  text-align: left;
  font-size: 18px;
`;

export const ProductInformation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: left;
  font-size: 18px;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

export const InformationDetails = styled.div`
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;

  h1 {
    flex-direction: column;
    width: auto;
    text-align: left;
    font-size: 18px;

    height: flex;
    border-bottom: 0px;
  }

  @media (max-width: 700px) {
    margin-left: 0px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 500;

  svg {
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }

  & > button {
    margin-right: 20px;
  }

  @media (max-width: 700px) {
    flex-direction: column;
    margin-right: 0px;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

export const CurrentPurchaseContainer = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    max-width: 500px;
    text-align: left;
    font-size: 20px;

    height: 30px;
    float: left;
    border-bottom: 2px solid #ac0000;
  }
`;

export const CurrentPurchase = styled.div`
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

export const Conditions = styled.div`
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 700px) {
    margin-left: 0px;
  }
`;

export const HistoricPurchaseContainer = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    max-width: 500px;
    text-align: left;
    font-size: 20px;

    height: 30px;
    float: left;
    border-bottom: 2px solid #ac0000;
  }
`;

export const HistoricPurchase = styled.div`
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

export const HistoryConditions = styled.div`
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;

  a {
    background: #ac0000;
    height: 56px;
    border-radius: 10px;
    border: 0;
    padding: 0.5rem 1rem;
    color: white;
    font-size: 1rem;
    width: 100%;
    font-weight: 500;
    margin-top: 16px;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;

    &:hover {
      background: ${shade(0.2, '#ac0000')};
    }
  }

  @media (max-width: 700px) {
    margin-left: 0px;
  }
`;
