import { CircularProgress } from '@material-ui/core';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import React, { useCallback, useRef, useState } from 'react';

import { useHistory } from 'react-router-dom';
import Footer from '../../components/Footer';
import HeaderWeb from '../../components/HeaderWeb';
import { Container } from './style';
import Button from '../../components/Button';
import Input from '../../components/Input';
import TextArea from '../../components/TextArea';
import getValidationErrors from '../../utils/getValidationErrors';
import api from '../../services/api';
import { useToast } from '../../hooks/ToastContext';

interface ContactForm {
  name: string;
  email: string;
  message: string;
}

const Contact: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [apiLoading, setApiLoading] = useState(false);
  const toast = useToast();
  const { addToast } = useToast();

  const handleContactForm = useCallback(
    async (data: ContactForm) => {
      setApiLoading(true);

      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('campo obrigatório'),
          email: Yup.string()
            .required('e-mail obrigatório')
            .email('Digite um e-mail válido'),
          message: Yup.string().required('campo obrigatório'),
        });

        await schema.validate(data, { abortEarly: false });

        await api.post('/contact', data);

        toast.addToast({
          title: 'Mensagem enviada com Sucesso',
        });

        formRef.current?.reset();
        // history.push('/');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
        addToast({
          type: 'error',
          title: 'Não foi possível enviar a sua mensagem',
          description: err.response.data.message,
        });
      }
      setApiLoading(false);
    },
    [toast, addToast]
  );

  return (
    <>
      <HeaderWeb />
      <Container>
        <div className="second-container">
          <div />
          <div className="form-container">
            <Form ref={formRef} onSubmit={handleContactForm}>
              <Input name="name" type="text" placeholder="Nome Completo" />
              <Input name="email" type="text" placeholder="E-mail" />
              <TextArea
                name="message"
                placeholder="Mensagem"
                rows={4}
                cols={50}
              />
              <Button type="submit" loading={apiLoading}>
                ENVIAR
              </Button>
            </Form>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default Contact;
