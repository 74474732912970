import React from 'react';
import { Switch } from 'react-router-dom';

import SignIn from '../pages/LogIn';

import Home from '../pages/Home';
import ForgotPass from '../pages/ForgotPass';
import Dashboard from '../pages/Dashboard';
import Route from './Route';
import ProductList from '../pages/ProductList';
import ProductDetail from '../pages/ProductDetail';
import Cart from '../pages/Cart';
import Checkout from '../pages/Checkout';
import CreateAccount from '../pages/CreateAccount';
import Contact from '../pages/Contact';
import Purchase from '../pages/Purchase';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={Home} />
    <Route path="/signin" component={SignIn} />
    <Route path="/forgot" component={ForgotPass} />
    <Route path="/newaccount" component={CreateAccount} />
    <Route path="/contact" component={Contact} />

    <Route path="/store" component={Dashboard} isPrivate />
    <Route path="/products-list" component={ProductList} isPrivate />
    <Route path="/product-detail" component={ProductDetail} isPrivate />
    <Route path="/cart" component={Cart} isPrivate />
    <Route path="/checkout" component={Checkout} isPrivate />
    <Route path="/purchase" component={Purchase} isPrivate />
  </Switch>
);
export default Routes;
