import React from 'react';
import { useHistory } from 'react-router-dom';
import Carousel from 'react-elastic-carousel';
import HeaderWeb from '../../components/HeaderWeb';
import img1 from '../../assets/image-grid-home/44.jpg';
import img2 from '../../assets/image-grid-home/10575.jpg';
import ImgPem from '../../assets/parceiros/pastelemel.webp';
import ImgGhada from '../../assets/parceiros/ghada.jpeg';
import ImgSabor from '../../assets/parceiros/sabor-caseiro.jpeg';
import ImgCaco from '../../assets/parceiros/cacos.jpeg';
import ImgCamilo from '../../assets/parceiros/camilo.jpg';
import ImgDivo from '../../assets/parceiros/casadivo.png';
import ImgComidaria from '../../assets/parceiros/comidaria.jpg';
import ImgDrag from '../../assets/parceiros/drag.jpg';
import ImgEster from '../../assets/parceiros/ester.jpg';
import ImgBrasli from '../../assets/parceiros/brasli.jpg';
import ImgGramado from '../../assets/parceiros/gramado.jpg';
import ImgVegas from '../../assets/parceiros/vegas.png';
import ImgFett from '../../assets/parceiros/fett.png';
import ImgSanto from '../../assets/parceiros/santo.jpg';
import ImgAero from '../../assets/parceiros/aero.jpg';
import ImgSmall from '../../assets/parceiros/small.jpg';
import ImgWings from '../../assets/parceiros/wings.webp';
import ImgKlub from '../../assets/parceiros/klub.jpg';
import ImgJamo from '../../assets/parceiros/jamo.jpg';
import ImgSantoiche from '../../assets/parceiros/santoiche.jpg';
import ImgHavanas from '../../assets/parceiros/havanas.jpg';
import ImgMaranelo from '../../assets/parceiros/maranelo.png';
import ImgDujesus from '../../assets/parceiros/dujesus.jpg';
import ImgZaki from '../../assets/parceiros/zaki.jpg';

import './style.css';

import { About, Parceiros, PresentationSection } from './styles';
import Footer from '../../components/Footer';

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2, itemsToScroll: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 5 },
];

const Home: React.FC = () => {
  const history = useHistory();

  if (window.innerWidth <= 800) history.push('/store');

  return (
    <>
      <HeaderWeb />
      <PresentationSection>
        <div className="presentation-text">
          <h1>
            As Melhores <span>Carnes</span> de Londrina estão <span>Aqui</span>!
          </h1>
          <button
            type="button"
            onClick={() => {
              history.push('/store');
            }}
          >
            Visite Nossa Loja
          </button>
        </div>
      </PresentationSection>
      <About>
        <h1>Bem Vindo à Distribuidora de Carnes Santa Carne Londrina</h1>
        <h3>Aqui você desfruta da qualidade que merece!</h3>
        <div className="description">
          <p>
            A distribuidora de carnes Santa Carne se destaca pela qualidade do
            serviço oferecido e excelência em todos os processo, desde a escolha
            da carne, manipulação, embalagem e entrega de seus produtos. Todos
            os produtos são embalados à vácuo a melhor conservação e qualidade.
            Nosso objetivo é garantir o melhor atendimento aos clientes suprindo
            suas necessidades e surpreendendo suas espectativas.
          </p>
          <div className="image-grid">
            <img src={img1} alt="" />
            <img src={img2} alt="" />
          </div>
        </div>
      </About>
      <Parceiros>
        <h1>Empresas que Atendemos</h1>
        <Carousel breakPoints={breakPoints} enableAutoPlay>
          <img src={ImgGhada} alt="Ghada Esfirraria" />
          <img src={ImgCaco} alt="Caco Self Service" />
          <img src={ImgSabor} alt="Sabor Caseiro" />
          <img src={ImgCamilo} alt="Camilo" />
          <img src={ImgPem} alt="Pastel e Mel" />
          <img src={ImgDivo} alt="Divo" />
          <img src={ImgComidaria} alt="Comidaria" />
          <img src={ImgDrag} alt="Drag Burger" />
          <img src={ImgEster} alt="Restaurante da Ester" />
          <img src={ImgBrasli} alt="Brasili Cozinha Chinesa" />
          <img src={ImgGramado} alt="Di Gramado Mix" />
          <img src={ImgVegas} alt="Vegas Burger Hamburgueria" />
          <img src={ImgFett} alt="Fett Burger" />
          <img src={ImgSanto} alt="Santo Burgers" />
          <img src={ImgAero} alt="Aero Rangus" />
          <img src={ImgSmall} alt="Small Burger" />
          <img src={ImgWings} alt="Wings Burger" />
          <img src={ImgKlub} alt="Klub Burger e Espetaria" />
          <img src={ImgJamo} alt="Jamo Bar" />
          <img src={ImgSantoiche} alt="Santoiche Hamburgueria" />
          <img src={ImgHavanas} alt="Havanas Burguer" />
          <img src={ImgMaranelo} alt="Pizzaria Maranello" />
          <img src={ImgDujesus} alt="Pastel Du Jesus" />
          <img src={ImgZaki} alt="Zaki Sabor Árabe" />
        </Carousel>
      </Parceiros>
      <Footer />
    </>
  );
};

export default Home;
