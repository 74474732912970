import styled from 'styled-components';

export const Container = styled.button`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  max-height: 75px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ac0000;
  color: white;
  .cart {
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      margin-left: 1rem;
      font-size: 16px;
      color: #fff;
      font-weight: bold;
    }
  }

  @media (min-width: 800px) {
    position: relative;
    border-radius: 4px;
    pointer-events: none;
  }
`;
