import styled from 'styled-components';
import ImgChurras from '../../assets/churras.jpg';

export const PresentationSection = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  background-image: url(${ImgChurras});
  background-position: center;
  background-size: cover;

  height: 90vh;

  .presentation-text {
    max-width: 800px;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    padding: 2rem;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h1 {
      font-size: 3rem;
      text-align: center;
      span {
        color: #fcba03;
      }
    }
    button {
      margin-top: 1rem;
      padding: 1rem;
      background: transparent;
      color: white;
      font-weight: bold;
      font-size: 1.5rem;
      border: 2px #fcba03 solid;
      border-radius: 5px;
      transition: 0.3s;
      &:hover {
        color: black;
        background: #fcba03;
      }
    }
  }
`;

export const About = styled.section`
  max-width: 900px;
  margin: auto;
  padding: 1rem;
  text-align: center;
  h3 {
    color: #4d4d4d;
    margin: 1rem;
  }

  .description {
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      text-align: justify;
      line-height: 2rem;
      color: #4d4d4d;
    }
    .image-grid {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      img {
        width: 250px;
        margin: 1rem;
        border-radius: 10px;
      }
    }
  }
`;

export const Parceiros = styled.section`
  margin: auto;
  padding: 1rem;
  text-align: center;
  background: #4d4d4d;
  color: white;
  overflow: hidden;
  h1 {
    margin: 1rem;
  }
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    li {
      margin: 14px;
    }
  }
  img {
    width: 250px;
    height: 250px;
    border: 12px white solid;
    border-radius: 150px;
  }
`;
