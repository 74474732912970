import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface MaterialAlertProps {
  title: string;
  description: string;
  agree?: string;
  disagree?: string;
  open: boolean;
  onClose: (response: boolean) => void;
}

const MaterialAlert: React.FC<MaterialAlertProps> = ({
  open,
  title,
  description,
  agree,
  disagree,
  onClose,
}) => {
  const handleClose = (response: boolean): void => {
    onClose(response);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {disagree && (
          <Button
            onClick={() => {
              handleClose(false);
            }}
            color="primary"
          >
            Disagree
          </Button>
        )}
        {agree && (
          <Button
            onClick={() => {
              handleClose(true);
            }}
            color="primary"
            autoFocus
          >
            Ok
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default MaterialAlert;
