import styled from 'styled-components';

interface AlertProps {
  open: boolean;
}

export const Container = styled.div<AlertProps>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  display: ${({ open }) => (!open ? 'none' : 'unset')};
  z-index: 10000;
`;

export const Content = styled.div`
  background: white;
  margin: auto;
  padding: 1rem;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    color: #ac0000;
  }
  p {
    text-align: justify;
  }
  .buttons {
    align-self: flex-end;
    button {
      padding: 0.5rem;
      background: #ac0000;
      color: white;
      border-radius: 5px;
      &:first-child {
        margin-right: 1rem;
      }
    }
  }
`;
