import styled from 'styled-components';
import { TextField, Tooltip } from '@material-ui/core';

export const CustomTextField = styled(TextField)`
  width: 100%;
  color: #ac0000;
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;
  svg {
    margin: 0;
  }
  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;

export const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  color: #c53030;
`;
