import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FaShoppingCart } from 'react-icons/fa';
import { BiMinus, BiPlus } from 'react-icons/bi';
import { useCart } from '../../hooks/cart';
import Container from './style';
import formatValue from '../../utils/formatValue';
import formatUnits from '../../utils/formatUnits';
import Burger from '../../components/Burger';
import Menu from '../../components/Menu';

const Cart: React.FC = () => {
  const { increment, decrement, cart } = useCart();
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const state = location.state as
    | {
        title: string;
        total: number;
      }
    | undefined;
  const history = useHistory();
  const cartTotal = useMemo(() => {
    if (cart) {
      const total = cart.reduce(
        (accumulator: number, curr) =>
          accumulator + curr.product.price * curr.quantity,
        0
      );
      return total;
    }
    return 0;
  }, [cart]);

  const handleIncrement = useCallback(
    (id: string) => {
      increment(id);
    },
    [increment]
  );

  const handleDecrement = useCallback(
    (id: string) => {
      decrement(id);
    },
    [decrement]
  );

  const handleNavigateToCheckout = useCallback(() => {
    history.push({
      pathname: '/checkout',
      state: {
        subtotal: state?.total || cartTotal,
        isPromoCart: !!state?.title,
        title: state?.title || undefined,
      },
    });
  }, [cartTotal, history, state]);

  const [totalCart, setTotalCart] = useState(0);
  useEffect(() => {
    setTotalCart(state?.total || cartTotal);
  }, [cartTotal, state, totalCart]);

  return (
    <Container>
      <Menu open={open} setOpen={setOpen} />
      <div className="section-header">
        <Burger open={open} setOpen={setOpen} />
        <h3>Carrinho de Compras</h3>
        <div />
      </div>
      {cart.length > 0 ? (
        <>
          <ul>
            {cart.map((item) => (
              <li key={item.id}>
                <img src={item.product.imageUrl} alt={item.product.name} />
                <div className="info-container">
                  <p className="product-name">{item.product.name}</p>

                  <p id="info">{item.cut && item.cut.name}</p>
                  <p id="info">{formatValue(item.product.price)}</p>
                  <div className="price-container">
                    <div className="total-container">
                      <p className="quantity">
                        {item.product.category.type === 'itens'
                          ? `${item.quantity}und -`
                          : `${formatUnits(item.quantity)}Kg -`}
                      </p>
                      <p>{formatValue(item.product.price * item.quantity)}</p>
                    </div>
                  </div>
                </div>
                {!state && (
                  <div className="increment-container">
                    <button
                      type="button"
                      onClick={() => handleIncrement(item.id)}
                    >
                      <BiPlus />
                    </button>
                    <button
                      type="button"
                      onClick={() => handleDecrement(item.id)}
                    >
                      <BiMinus />
                    </button>
                  </div>
                )}
              </li>
            ))}
          </ul>
          <div className="cart-total">
            <FaShoppingCart />
            <h2>Total:</h2>
            <p>{formatValue(totalCart)}</p>
          </div>
          <button
            className="button-class"
            type="button"
            onClick={handleNavigateToCheckout}
          >
            Finalizar Pedido
          </button>
        </>
      ) : (
        <div className="empty">
          <h1>CARRINHO VAZIO</h1>
          <button
            className="button-class"
            type="button"
            onClick={() => {
              history.push('/products-list');
            }}
          >
            Acesse a Lista de Produtos
          </button>
        </div>
      )}
    </Container>
  );
};
export default Cart;
