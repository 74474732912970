import React, { useState, useCallback, useRef, useEffect } from 'react';
import * as Yup from 'yup';
import {
  Button,
  createMuiTheme,
  ThemeProvider,
  CircularProgress,
} from '@material-ui/core';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { cepMask, phoneMask } from '../../utils/masks';
import { useToast } from '../../hooks/ToastContext';
import MaterialInput from '../MaterialInput';
import getValidationErrors from '../../utils/getValidationErrors';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';

interface AddressFormData {
  cep: string;
  street: string;
  number: number;
  more: string;
  phone: string;
}

interface Address {
  id: string;
  cep: string;
  street: string;
  number: number;
  more: string;
  phone: string;
  frete: string;
  updated_at: string;
  created_at: string;
}

interface AddressFormProps {
  confirmation: (val: Address) => void;
}

const AdressForm: React.FC<AddressFormProps> = ({ confirmation }) => {
  const theme = createMuiTheme({ palette: { primary: { main: '#ac0000' } } });
  const formRef = useRef<FormHandles>(null);
  const { signOut } = useAuth();
  const { addToast } = useToast();
  const [cep, setCep] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [street, setStreet] = useState<string>();
  const [number, setNumber] = useState<string>();
  const [more, setMore] = useState<string>();
  const [sendingAddress, setSendingAddress] = useState(false);

  useEffect(() => {
    api
      .get('/address')
      .then((res) => {
        const addresses: Address[] = res.data;
        const newest = addresses.pop();
        if (newest) {
          setCep(cepMask(newest.cep));
          setStreet(newest.street);
          setNumber(newest.number.toString());
          setMore(newest.more);
          setPhone(phoneMask(newest.phone));
        }
      })
      .catch(() => {
        signOut();
      });
  }, [signOut]);

  const handleAddressSubmit = useCallback(
    async (data: AddressFormData) => {
      setSendingAddress(true);
      try {
        formRef.current?.setErrors({});
        const addressSchema = Yup.object().shape({
          cep: Yup.string()
            .required('campo cep é obrigatório')
            .length(9, 'Confira o CEP informado'),
          street: Yup.string().required('campo obrigatório'),
          number: Yup.string().required('campo obrigatório'),
          more: Yup.string().required('campo obrigatório'),
          phone: Yup.string()
            .min(13)
            .required('Telefone deve estar no formato (XX) XXXX-XXXX'),
        });

        await addressSchema.validate(data, {
          abortEarly: false,
        });
        api
          .post('/address', {
            ...data,
            cep: cep?.replace(/\D/g, ''),
          })
          .then((response) => {
            if (!response.data)
              addToast({
                type: 'error',
                title: 'Ainda não atendemos sua região',
                description:
                  'Por enquanto apenas atendemos Londrina, confira se seu CEP está correto ou entre em contato com um de nossos desenvolvedores.',
              });
            else {
              addToast({
                type: 'success',
                title: 'Endereço cadastrado com sucesso',
              });
              confirmation(response.data);
            }
          });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          setSendingAddress(false);
          return;
        }
        addToast({
          type: 'error',
          title: 'Erro no cadastro do endereço',
          description:
            'Ocorreu um erro ao realizar o cadastro do endereço, tente novamente.',
        });
      }
      setSendingAddress(false);
    },
    [addToast, cep, confirmation]
  );

  return (
    <ThemeProvider theme={theme}>
      <Form ref={formRef} onSubmit={handleAddressSubmit}>
        <MaterialInput
          name="cep"
          type="text"
          label="CEP"
          value={cep || ''}
          onChange={(event: { target: { value: string } }) => {
            setCep(cepMask(event.target.value));
          }}
        />
        <MaterialInput
          value={street || ''}
          onChange={(event: { target: { value: string } }) => {
            setStreet(event.target.value);
          }}
          name="street"
          type="decimal"
          label="Rua/Avenida"
        />
        <MaterialInput
          value={number || ''}
          onChange={(event: { target: { value: string } }) => {
            setNumber(event.target.value);
          }}
          name="number"
          type="decimal"
          label="Número"
        />
        <MaterialInput
          value={more || ''}
          onChange={(event: { target: { value: string } }) => {
            setMore(event.target.value);
          }}
          name="more"
          type="text"
          label="Complemento"
        />
        <MaterialInput
          name="phone"
          type="text"
          label="Telefone com ddd"
          value={phone || ''}
          onChange={(event: { target: { value: string } }) => {
            setPhone(phoneMask(event.target.value));
          }}
        />
        <Button
          style={{ width: '100%', padding: 12 }}
          variant="contained"
          color="primary"
          type="submit"
        >
          {sendingAddress && <CircularProgress style={{ color: '#fff' }} />}
          CONFIRMAR ENDEREÇO
        </Button>
      </Form>
    </ThemeProvider>
  );
};

export default AdressForm;
