import React, { useState, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { BiMinus, BiPlus } from 'react-icons/bi';
import { Editor, EditorState, convertFromRaw } from 'draft-js';
import Container from './style';
import { Cut, Product } from '../../interfaces/CartStructure';
import formatUnits from '../../utils/formatUnits';
import formatValue from '../../utils/formatValue';
import FloatingCart from '../../components/FloatingCart';
import { useCart } from '../../hooks/cart';
import { Alert, AlertProps } from '../../components/Alert';

const ProductDetail: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { product } = location.state as { product: Product };
  const { addToCart } = useCart();
  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState(product.price / 2);
  const [cut, setCut] = useState<Cut | undefined>(undefined);
  const [alert, setAlert] = useState<Omit<AlertProps, 'onClose'>>({
    open: false,
    title: '',
    description: '',
  });

  let editorState;
  try {
    JSON.parse(product.description);
    editorState = EditorState.createWithContent(
      convertFromRaw(JSON.parse(product.description))
    );
  } catch {
    editorState = undefined;
  }

  const handleAddToCart = useCallback(
    (item: Product) => {
      addToCart(item, quantity, cut);
      setQuantity(1);
      setPrice(0);
      setCut(undefined);
      history.push('/products-list');
    },
    [addToCart, cut, history, quantity]
  );

  const handleSubtract = useCallback(() => {
    let new_quantity = quantity;
    if (product.category.type === 'itens') {
      if (quantity <= 1) return;
      new_quantity = quantity - 1;
    } else {
      if (quantity <= 0.5) return;
      new_quantity = quantity - 0.5;
    }
    setQuantity(new_quantity);
    setPrice(new_quantity * product.price);
  }, [product.category.type, product.price, quantity]);

  const handleAdd = useCallback(() => {
    let new_quantity = quantity;
    if (product.category.type === 'itens') new_quantity = quantity + 1;
    else new_quantity = quantity + 0.5;
    setQuantity(new_quantity);
    setPrice(new_quantity * product.price);
  }, [product.category.type, product.price, quantity]);

  const closeAlert = useCallback(
    (response: boolean) => {
      if (alert) setAlert({ ...alert, open: false });
      if (!response && product)
        setCut(product.cuts ? product.cuts[0] : undefined);
      else setCut(product.cuts?.find((item) => item.name === 'peça'));
    },
    [alert, product]
  );

  return (
    <Container>
      <Alert
        open={alert.open}
        title={alert.title}
        description={alert?.description}
        onClose={closeAlert}
      />
      <div className="inner-container">
        <section className="first-section">
          <div className="imagem">
            <img src={product.imageUrl} alt={product.name} />
            <span className="disclamer">*imagens meramente ilustrativas</span>
          </div>
          <div className="content">
            <h1>{product.name}</h1>
            <p className="price">
              {product.category.type === 'itens'
                ? `${formatValue(product.price)} / und`
                : `${formatValue(product.price)} / Kg`}
            </p>
            <h2>Quantidade</h2>
            <div className="increment-container">
              <button type="button" onClick={handleSubtract}>
                <BiMinus />
              </button>
              <p className="product-amount">
                {product.category.type === 'itens'
                  ? `${formatUnits(quantity)} und`
                  : `${formatUnits(quantity)} Kg`}
              </p>
              <div className="increment-container">
                <button type="button" onClick={handleAdd}>
                  <BiPlus />
                </button>
              </div>
            </div>
            <div className="price-container">
              <h2>Valor</h2>
              <p>{formatValue(price)}</p>
            </div>
          </div>
        </section>
        <main>
          <div className="descrip">
            {product.category.type !== 'itens' &&
              (!product.cuts || product.cuts.length > 0) && (
                <>
                  <h2>Cortes</h2>
                  <select
                    id="cuts"
                    name="cuts"
                    value={cut?.name}
                    onChange={(event) => {
                      if (event.currentTarget.value === 'peça') {
                        setAlert({
                          open: true,
                          title: 'ATENÇÃO',
                          description:
                            'A peça inteira pode variar em peso e, por tanto, variar em preço. Logo, o preço exibido no cálculo irá distinguir do preço cobrado no ato da entrega.',
                          buttons: {
                            confirmation: { title: 'Confirmar' },
                            rejection: { title: 'Cancelar' },
                          },
                        });
                      } else {
                        setCut(
                          product.cuts?.find(
                            (item) => item.name === event.currentTarget.value
                          )
                        );
                      }
                    }}
                  >
                    {product.cuts?.map((cuty) => (
                      <option key={cuty.name} value={cuty.name}>
                        {cuty.name}
                      </option>
                    ))}
                  </select>
                </>
              )}
            <h2>Descrição</h2>
            {editorState ? (
              <Editor
                editorState={editorState}
                readOnly
                onChange={() => {
                  // todo
                  // todo
                }}
              />
            ) : (
              <p className="description">{product.description}</p>
            )}
          </div>
          <button type="button" onClick={() => handleAddToCart(product)}>
            Adicionar ao carrinho
          </button>
        </main>
      </div>
      <FloatingCart />
    </Container>
  );
};

export default ProductDetail;
