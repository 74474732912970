import React, {
  createContext,
  useState,
  useCallback,
  useContext,
  useEffect,
} from 'react';

import { v4 } from 'uuid';
import { CartItem, Cut, Product } from '../interfaces/CartStructure';

interface CartContext {
  cart: CartItem[];
  addToCart(item: Product, quantity: number, cut?: Cut): void;
  increment(id: string): void;
  decrement(id: string): void;
  loadCart(product: Product[]): Product[];
  setNewCart(
    data: {
      product: Product;
      quantity: number;
      cut?: Cut;
    }[]
  ): CartItem[];
  emptyCart(): void;
}

const CartContext = createContext<CartContext | null>(null);

const CartProvider: React.FC = ({ children }) => {
  const [cart, setCart] = useState<CartItem[]>([]);

  useEffect(() => {
    async function loadCart(): Promise<void> {
      const response = localStorage.getItem('@SantaCarne:cart');
      if (response) setCart(JSON.parse(response));
    }

    loadCart();
  }, []);

  const addToCart = useCallback(
    async (cartItem, quantity, cut) => {
      setCart([...cart, { product: { ...cartItem }, quantity, id: v4(), cut }]);
      localStorage.setItem('@SantaCarne:cart', JSON.stringify(cart));
    },
    [cart]
  );

  const emptyCart = useCallback(async () => {
    setCart([]);
    localStorage.setItem('@SantaCarne:cart', JSON.stringify([]));
  }, []);

  const increment = useCallback(
    async (id) => {
      setCart(
        cart.map((item) => {
          let quant = item.quantity;
          if (typeof quant === 'string') {
            quant = parseFloat(quant);
          }
          return item.id === id
            ? {
                ...item,
                quantity:
                  quant + (item.product.category.type === 'itens' ? 1 : 0.5),
              }
            : item;
        })
      );
      localStorage.setItem('@SantaCarne:cart', JSON.stringify(cart));
    },
    [cart]
  );

  const decrement = useCallback(
    async (id) => {
      const itemToRemove = cart.map((item) =>
        item.id === id
          ? {
              ...item,
              quantity:
                item.quantity +
                (item.product.category.type === 'itens' ? -1 : -0.5),
            }
          : item
      );
      const positiveQuantities = itemToRemove.filter(
        (item) => item.quantity > 0
      );

      setCart(positiveQuantities);

      localStorage.setItem('@SantaCarne:cart', JSON.stringify(cart));
    },
    [cart]
  );

  const loadCart = useCallback((chosenCart) => {
    setCart(chosenCart);
    return chosenCart;
  }, []);

  const setNewCart = useCallback((chosenCart) => {
    const dummyCart = chosenCart.map((item: Omit<CartItem, 'id'>) => ({
      ...item,
      id: v4(),
    }));
    setCart(dummyCart);
    localStorage.setItem('@SantaCarne:cart', JSON.stringify(dummyCart));
    return dummyCart;
  }, []);

  const value = React.useMemo(
    () => ({
      addToCart,
      increment,
      decrement,
      cart,
      loadCart,
      emptyCart,
      setNewCart,
    }),
    [addToCart, increment, decrement, cart, loadCart, emptyCart, setNewCart]
  );

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

function useCart(): CartContext {
  const context = useContext(CartContext);

  if (!context) {
    throw new Error(`useCart must be used within a CartProvider`);
  }

  return context;
}

export { CartProvider, useCart };
