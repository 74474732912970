import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { FiSearch } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { Container, CategoryItem } from './style';
import { Product } from '../../interfaces/CartStructure';
import api from '../../services/api';
import formatValue from '../../utils/formatValue';
import FloatingCart from '../../components/FloatingCart';
import Menu from '../../components/Menu';
import Burger from '../../components/Burger';

interface Category {
  id: string;
  name: string;
  imageUrl: string;
  products: Product[];
}

const ProductList: React.FC = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<Product[]>([]);
  const [search, setSearch] = useState('');
  const history = useHistory();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    api.get('/categories').then((response) => {
      setCategories(response.data);
      const { products } = response.data[0];
      setSelectedCategory(products);
    });
  }, [history]);

  const [productsToShow, setProductsToShow] = useState<Product[]>(
    selectedCategory
  );

  useEffect(() => {
    setProductsToShow(
      selectedCategory.filter(
        (item) =>
          item.name.toLowerCase().includes(search.toLowerCase()) ||
          (item.cuts &&
            item.cuts.filter((i) =>
              i.name.toLowerCase().includes(search.toLowerCase())
            ).length > 0)
      )
    );
  }, [search, selectedCategory]);

  function handleSelectCategory(id: string): void {
    const products = categories.find((category) => category.id === id)
      ?.products;

    if (!products) setSelectedCategory([]);
    else setSelectedCategory(products);
  }

  const navigateToProductDetail = useCallback(
    (product: Product) => {
      history.push({ pathname: '/product-detail', state: { product } });
    },
    [history]
  );

  const setFilteredCategories = useMemo(() => {
    categories.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
    return categories;
  }, [categories]);

  return (
    <Container>
      <Menu open={open} setOpen={setOpen} />
      <div className="section-header">
        <Burger open={open} setOpen={setOpen} />
        <h3>Nossos Produtos</h3>
        <div />
      </div>
      <section className="categories">
        <h1>Categorias</h1>
        <div className="h-slider">
          {setFilteredCategories.map((category) => (
            <CategoryItem
              className="category"
              key={category.id}
              isSelected={category.products === selectedCategory}
              onClick={() => handleSelectCategory(category.id)}
            >
              <img src={category.imageUrl} alt="category.name" />
            </CategoryItem>
          ))}
        </div>
      </section>
      <main className="products">
        <h1>Produtos</h1>
        <div className="search-bar">
          <FiSearch />
          <input
            type="text"
            placeholder="Procurar"
            onChange={(value) => {
              setSearch(value.target.value);
            }}
          />
        </div>
        <div className="list">
          {productsToShow.map((item) => (
            <button
              type="button"
              className="product-card"
              key={item.id}
              onClick={() => navigateToProductDetail(item)}
            >
              <img src={item.imageUrl} alt={item.name} />
              <div className="info">
                <h2>{item.name}</h2>
                <span>{formatValue(item.price)}</span>
              </div>
            </button>
          ))}
        </div>
      </main>
      <FloatingCart />
    </Container>
  );
};

export default ProductList;
