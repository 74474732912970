import styled from 'styled-components';

export const Container = styled.footer`
  background: black;
  color: lightgray;
  width: 100%;
  margin: 0;
  .footer-container {
    background: transparent;
    margin: auto;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;

    img {
      width: 200px;
      margin: 1rem;
    }

    div {
      width: 300px;
      margin: 1rem;
      p {
        margin: 1rem 0;
      }
    }
  }
`;
