import styled from 'styled-components';

export default styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  .inner-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 75px;
    background: white;
    margin: 1rem;
  }

  h2 {
    margin-top: 13px;
    font-size: 14px;
    color: #ac0000;
  }

  .first-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    gap: 1rem;
    .imagem {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    img {
      width: 150px;
      height: 150px;
    }
    .disclamer {
      font-size: 0.65rem;
    }
    .content {
      flex: 1;
      h1 {
        font-size: 20px;
        color: #ac0000;
      }
      .price {
        font-size: 12px;
        color: #59595f;
      }
      .increment-container {
        display: flex;
        align-items: center;
        justify-content: center;
        .product-amount {
          font-size: 12px;
          color: #59595f;
        }
      }
      .price-container {
        p {
          padding-left: 20px;
          font-size: 20px;
          margin: 0 15px;
        }
      }
      button {
        width: 30px;
        height: 30px;
        border-radius: 3px;
        background: rgba(232, 61, 91, 0.1);
        align-items: center;
        justify-content: center;
        color: #ac0000;
        margin: 0.5rem;
        svg {
          background: transparent;
        }
      }
    }
  }

  main {
    display: flex;
    flex-direction: column;
    align-items: center;

    .descrip {
      margin-left: 1rem;

      select {
        width: 80%;
      }
    }
    button {
      background: #ac0000;
      color: white;
      padding: 0.5rem;
      margin: 1rem auto;
      border-radius: 5px;
    }
  }
`;
