import styled from 'styled-components';

export const Container = styled.nav`
  position: sticky;
  left: 0;
  top: 0;
  display: grid;
  grid-template-columns: 200px 1fr 1fr 1fr;
  grid-template-rows: 80px;
  grid-template-areas: 'logo nav nav nav';
  .logo {
    background: #ac0000;
    grid-area: logo;
    padding: 20px;
    display: flex;
    align-items: center;
    img {
      width: 100%;
    }
  }
`;
export const NavBar = styled.div`
  grid-area: nav;
  background: #ac0000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;

  ul {
    display: flex;
    align-items: center;
    color: white;
    li {
      padding: 20px;
      a {
        color: white;
      }
    }
  }
`;
