import React, { useCallback, useRef, useState } from 'react';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { CircularProgress } from '@material-ui/core';
import * as Yup from 'yup';
import { FiLock, FiMail } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import getValidationErrors from '../../utils/getValidationErrors';
import Input from '../../components/Input';

import logoImg from '../../assets/logo1.png';

import Button from '../../components/Button';
import { Container } from './style';
import api from '../../services/api';
import { useToast } from '../../hooks/ToastContext';

interface SignInFormData {
  email: string;
  password: string;
}

const ForgotPass: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [apiLoading, setApiLoading] = useState(false);
  const { addToast } = useToast();

  const handleSignIn = useCallback(
    async (data: SignInFormData) => {
      setApiLoading(true);
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/password/forgot', data);
        history.push('/signin');
        setApiLoading(false);
      } catch (err) {
        setApiLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
        addToast({
          type: 'error',
          title: 'Não foi possível enviar o email',
          description: err.response.data.message,
        });
      }
    },
    [history, addToast]
  );

  return (
    <Container>
      <img src={logoImg} alt="Santa Carne Logo" className="logo" />
      <h1>Esqueceu a senha? Enviaremos para seu e-mail</h1>
      <Form ref={formRef} onSubmit={handleSignIn}>
        <Input name="email" type="text" placeholder="E-mail" icon={FiMail} />
        <Button type="submit">
          {apiLoading && <CircularProgress style={{ color: '#fff' }} />}Enviar
        </Button>
      </Form>
      <Link to="/signin" id="create-account">
        voltar
      </Link>
    </Container>
  );
};

export default ForgotPass;
