import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;

  .section-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    margin: 0;
    background: #ac0000;
    color: #fff;

    h3 {
      width: 100%;
      text-align: center;
    }
  }

  ul {
    border-radius: 5px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    padding: 0 10px;
    overflow-y: auto;
    width: 100%;
    li {
      background: #fff;
      padding: 15px 10px;
      border-radius: 5px;
      margin: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        height: 92px;
        width: 92px;
      }
      .info-container {
        font-size: 16px;
        margin-left: 5px;
        flex: 1;
        p {
          color: #ac0000;
        }
        .product-name {
          font-size: 16px;
          color: #000;
        }
        #info {
          font-size: 12px;
          color: #a0a0b3;
          margin-top: 8px;
        }
        .total-container {
          display: flex;
          p {
            font-weight: bold;
            margin-top: 5px;

            font-size: 16px;
            color: #e83f5b;
          }
          .quantity {
            margin-right: 10px;
          }
        }
      }
      .increment-container {
        align-self: flex-end;
        display: flex;
        flex-direction: column;
        align-items: center;
        button {
          width: 30px;
          height: 30px;
          border-radius: 3px;
          background: rgba(232, 61, 91, 0.1);
          align-items: center;
          justify-content: center;
          color: #ac0000;
          margin: 0.5rem;
          svg {
            background: transparent;
          }
        }
      }
    }
  }
  .cart-total {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    color: #ac0000;

    h2 {
      font-size: 1rem;
      margin: 0 0.5rem;
    }
    p {
      color: #000;
      font-size: 1.3rem;
      margin: 0 0.5rem;
    }
  }
  .button-class {
    width: 100%;
    padding: 0.5rem;
    color: white;
    margin-top: 1rem;
    background: #ac0000;
  }

  .empty {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h1 {
      font-size: 1.5rem;
    }
    button {
      border-radius: 5px;
      border: 0;
      background: #ac0000;
      color: white;
      padding: 0.5rem;
      margin: 1rem;
    }
  }
`;
