import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.button`
  background: #ac0000;
  height: 56px;
  border-radius: 10px;
  border: 0;
  padding: 0.5rem 1rem;
  color: white;
  font-size: 1rem;
  width: 100%;
  font-weight: 500;
  margin-top: 16px;
  transition: background-color 0.2s;
  cursor: pointer;

  &:hover {
    background: ${shade(0.2, '#ac0000')};
  }
`;
