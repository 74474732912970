import React, { useState, useEffect, useCallback } from 'react';

import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';
import { CartItem } from '../../interfaces/CartStructure';
import { useCart } from '../../hooks/cart';
import Mobile from './Mobile';
import Web from './Web';

export interface CartCard {
  id?: string;
  title?: string;
  cartItens: CartItem[];
  cartTotal?: number;
  imageUrl?: string;
}

const Dashboard: React.FC = () => {
  const { loadCart } = useCart();
  const history = useHistory();
  const [promoCarts, setPromoCarts] = useState<CartCard[]>([]);
  const { signOut } = useAuth();

  useEffect(() => {
    api
      .get('/cart/promo-cart')
      .then((response) => {
        setPromoCarts(response.data);
      })
      .catch(() => {
        signOut();
      });
  }, [signOut]);

  const navigateToCart = useCallback(
    (chosenCart, total?, title?) => {
      loadCart(chosenCart);
      if (total) history.push({ pathname: '/cart', state: { total, title } });
      else history.push('/cart');
    },
    [loadCart, history]
  );

  if (window.innerWidth <= 800)
    return (
      <Mobile
        loadCart={loadCart}
        navigateToCart={navigateToCart}
        promoCarts={promoCarts}
      />
    );

  return <Web />;
};

export default Dashboard;
