import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container } from './style';
import { CartCard } from '..';
import { CartItem, Product } from '../../../interfaces/CartStructure';
import { useCart } from '../../../hooks/cart';
import formatValue from '../../../utils/formatValue';
import Burger from '../../../components/Burger';
import Menu from '../../../components/Menu';

interface MobileProps {
  promoCarts: CartCard[];
  navigateToCart: (chosenCart: any, total?: any, title?: any) => void;
  loadCart: (chosenCart: Product[]) => Product[];
}

let deferredPrompt: any;

const Mobile: React.FC<MobileProps> = ({
  promoCarts,
  navigateToCart,
  loadCart,
}) => {
  const { cart } = useCart();
  const [open, setOpen] = useState(false);
  const [installable, setInstallable] = useState(false);

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (e) => {
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      // Update UI notify the user they can install the PWA
      setInstallable(true);
    });
  }, []);

  const handleInstallClick = useCallback(() => {
    if (!deferredPrompt) return;
    // Hide the app provided install promotion
    setInstallable(false);
    // Show the install prompt
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then((choiceResult: any) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the install prompt');
      } else {
        console.log('User dismissed the install prompt');
      }
    });
  }, []);

  const cartTotal = useMemo(() => {
    const total = cart.reduce(
      (accumulator: number, curr: CartItem) =>
        accumulator + curr.product.price * curr.quantity,
      0
    );
    return formatValue(total);
  }, [cart]);

  const sortedCarts = useMemo(() => {
    return promoCarts.sort((a, b) => {
      if (a.title && b.title) return a.title > b.title ? 1 : -1;
      return -1;
    });
  }, [promoCarts]);

  return (
    <Container>
      <Menu open={open} setOpen={setOpen} />
      <header />
      <section id="product-bar">
        <Burger open={open} setOpen={setOpen} />
        <Link
          to="/products-list"
          onClick={() => {
            loadCart([]);
          }}
        >
          LISTA DE PRODUTOS
        </Link>
        <div />
      </section>
      <main className="list">
        <h1>Último Pedido</h1>
        <div className="my-card">
          <button
            type="button"
            onClick={() => {
              navigateToCart(cart);
            }}
          >
            ABRIR CARRINHO
          </button>
          <p>{cartTotal}</p>
        </div>
        <h1>Nossos Kits Promocionais</h1>
        {sortedCarts.map((promo) => (
          <div key={promo.id}>
            <button
              type="button"
              onClick={() => {
                navigateToCart(promo.cartItens, promo.cartTotal, promo.title);
              }}
            >
              <img
                src={promo.imageUrl}
                alt={promo.title}
                className="promo"
                key={promo.id}
              />
            </button>
          </div>
        ))}
      </main>
      {installable && (
        <div className="install-button">
          <button type="button" onClick={handleInstallClick}>
            Instalar Aplicativo
          </button>
        </div>
      )}
    </Container>
  );
};
export default Mobile;
