import React, { useMemo, useCallback } from 'react';
import { FaShoppingCart } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { Container } from './style';
import { useCart } from '../../hooks/cart';
import formatValue from '../../utils/formatValue';

const FloatingCart: React.FC = () => {
  const { cart } = useCart();
  const history = useHistory();

  const cartTotal = useMemo(() => {
    if (cart.length >= 1) {
      const total = cart.reduce((accumulator: number, curr) => {
        console.log(curr);
        if (!curr.product) return 0;
        console.log(curr.product.price, curr.quantity);
        return accumulator + curr.product.price * curr.quantity;
      }, 0);
      return formatValue(total);
    }
    return formatValue(0);
  }, [cart]);

  const totalItensInCart = useMemo(() => {
    return cart.length;
  }, [cart]);

  const floatCartText = useCallback(() => {
    switch (totalItensInCart) {
      case 0:
        return 'vazio';
      case 1:
        return `${totalItensInCart} item`;
      default:
        return `${totalItensInCart} itens`;
    }
  }, [totalItensInCart]);

  return (
    <Container type="button" onClick={() => history.push('/cart')}>
      <div className="cart">
        <FaShoppingCart />
        <p>{floatCartText()}</p>
      </div>
      <p>{cartTotal}</p>
    </Container>
  );
};
export default FloatingCart;
