import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  *{
    padding:0;
    margin:0;
    box-sizing:border-box;
    font-family: Roboto, sans-serif;
  }
  body{
    background: #f0f0f5;
  }
  a{
    text-decoration:none;
  }
  button{
    border:0;
  }
  ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
`;
export default GlobalStyle;
