import React, { useCallback } from 'react';
import { Container, Content } from './style';

export interface AlertProps {
  open: boolean;
  title: string;
  description: string;
  variable?: string;
  onClose: (response: boolean) => void;
  buttons?: { confirmation: { title: string }; rejection?: { title: string } };
}

export const Alert: React.FC<AlertProps> = (props) => {
  const { open, title, description, onClose, buttons } = props;

  const close = useCallback(
    (response: boolean) => {
      onClose(response);
    },
    [onClose]
  );

  return (
    <Container open={open}>
      <Content>
        <h1>{title}</h1>
        <p>{description}</p>
        {/* {buttons && ( */}
        <div className="buttons">
          {/* {buttons.rejection && ( */}
          <button
            type="button"
            onClick={() => {
              close(false);
            }}
          >
            {/* {buttons.rejection.title} */}
            Cancelar
          </button>
          {/* )} */}
          {/* {buttons.confirmation && ( */}
          <button
            type="button"
            onClick={() => {
              close(true);
            }}
          >
            {/* {buttons.confirmation.title} */}
            Confirmar
          </button>
          {/* )} */}
        </div>
        {/* )} */}
      </Content>
    </Container>
  );
};
