import styled from 'styled-components';
import { RadioGroup } from '@material-ui/core';

export const Container = styled.div`
  padding: 1rem;

  @media only screen and (min-width: 800px) {
    max-width: 900px;
    margin: auto;
  }

  .title {
    font-size: 1rem;
    color: #ac0000;
  }

  .brute-value {
    padding: 1rem;
    border-radius: 5px;
    border: 1px solid lightgray;
    margin: 2rem 0;
    text-align: center;
    span {
      color: #000;
      font-size: 1.2rem;
      margin-left: 1rem;
    }
  }

  .total {
    margin: 12px 0;
    padding: 1rem;
    border: 1px #c5c5c5 solid;
    border-radius: 4px;

    display: flex;
    align-items: center;

    .value {
      margin-left: 0.75rem;
    }
  }

  .nota {
    display: flex;
    align-items: center;
  }
`;

export const DeliveryGroup = styled(RadioGroup)`
  width: 100%;
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: center;
`;

export const NavBar = styled.div`
  grid-area: nav;

  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;

  ul {
    display: flex;
    align-items: center;
    color: white;
    li {
      padding: 20px;
    }
  }
`;

export const HeaderView = styled.div`
  display: grid;
  grid-template-columns: 162px 1fr 1fr 1fr;
  grid-template-rows: 80px;

  grid-template-areas: 'logo nav nav nav';

  background: #ac0000;

  .logo {
    grid-area: logo;
    padding: 20px;
    img {
      width: 100%;
    }
  }
`;
