import React from 'react';

import ImgLogo from '../../assets/redSanta.png';
import { Container } from './style';

const Footer: React.FC = () => (
  <Container>
    <div className="footer-container">
      <img src={ImgLogo} alt="Grupo Santa Carne" />
      <p>Av. São João, 2011 - Antares - CEP 86036-030</p>
      <p>CNPJ: - Londrina - Paraná - Brasil</p>
    </div>
  </Container>
);
export default Footer;
