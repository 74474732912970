import React, { useCallback } from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import { FaShoppingCart } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { CartCard } from '../../pages/Dashboard/Web';
import { useCart } from '../../hooks/cart';
import formatValue from '../../utils/formatValue';
import formatUnits from '../../utils/formatUnits';
import { Container } from './style';

interface PromoProps {
  promoCart?: CartCard;
  open: boolean;
  handleClose: () => void;
}

const WebPromoDialog: React.FC<PromoProps> = ({
  open,
  promoCart,
  handleClose,
}) => {
  const { loadCart, addToCart } = useCart();
  const history = useHistory();

  const handleBuyPromo = useCallback(() => {
    if (!promoCart) return;
    loadCart([]);
    // eslint-disable-next-line array-callback-return
    promoCart.cartItens.map((item): void => {
      addToCart(item.product, item.quantity);
    });
    history.push('/checkout', {
      subtotal: promoCart.cartTotal || 0,
      isPromoCart: true,
      title: promoCart.title,
    });
  }, [addToCart, history, loadCart, promoCart]);

  return (
    <Container
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {promoCart && (
        <>
          <DialogTitle id="alert-dialog-title">{promoCart.title}</DialogTitle>
          <DialogContent>
            <ul>
              {promoCart.cartItens.map((item) => (
                <li key={item.product.id}>
                  <img src={item.product.imageUrl} alt={item.product.name} />
                  <div className="info-container">
                    <p className="product-name">{item.product.name}</p>

                    <p id="info">{item.cut && item.cut.name}</p>
                    <p id="info">{formatValue(item.product.price)}</p>
                    <div className="price-container">
                      <div className="total-container">
                        <p className="quantity">
                          {item.product.category.type === 'itens'
                            ? `${item.quantity}und -`
                            : `${formatUnits(item.quantity)}Kg -`}
                        </p>
                        <p>{formatValue(item.product.price * item.quantity)}</p>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            <div className="cart-total">
              <FaShoppingCart />
              <h2>Total:</h2>
              <p>{formatValue(promoCart.cartTotal || 0)}</p>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleClose();
              }}
              color="primary"
            >
              Voltar
            </Button>
            <Button onClick={handleBuyPromo} color="primary" autoFocus>
              Realizar Pedido
            </Button>
          </DialogActions>
        </>
      )}
    </Container>
  );
};

export default WebPromoDialog;
