import styled from 'styled-components';
import ImgChurras from '../../assets/churras.jpg';

export const Container = styled.main`
  height: 100vh;
  background-image: url(${ImgChurras});
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;

  .second-container {
    max-width: 800px;
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      flex: 1;
    }
    .form-container {
      background-color: rgba(0, 0, 0, 0.6);
      padding: 2rem;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: column;
    }
  }
`;
