import styled, { css } from 'styled-components';

export const Container = styled.div`
  background: #f0f0f5;
  height: 100%;

  .section-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    margin: 0;
    background: #ac0000;
    color: #fff;

    h3 {
      width: 100%;
      text-align: center;
    }
  }

  h1 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #3d3d4d;
    padding: 0 20px;
  }
  .categories {
    .h-slider {
      display: flex;
      overflow-x: auto;
      align-items: center;
    }
  }

  .products {
    border-radius: 5px;
    margin-top: 20px;
    flex: 1;
    margin-bottom: 60px;

    .search-bar {
      display: block;
      align-items: center;
      margin-left: 2rem;
      border: 1px solid lightgray;
      padding: 0.5rem;
      border-radius: 5px;
      width: 80%;
      background: white;
      svg {
        margin-right: 1rem;
        background: white;
      }
      input {
        border: 0;
        outline: none;
        background: white;
      }
    }

    .list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
      margin: 1rem;
      overflow-y: auto;
      .product-card {
        background: white;
        -webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.55);
        -moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.55);
        box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.55);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        background: #fff;
        padding: 16px 16px;
        border-radius: 5px;
        margin: 8px;
        flex: 1;
        img {
          height: 100px;
          width: 100px;
          align-self: center;
        }
        .info {
          h2 {
            font-size: 14px;
            margin-top: 10px;
          }
          span {
            font-weight: bold;
            font-size: 16px;
            color: #e83f5b;
          }
        }
      }
    }
  }
`;

interface CategoryItemProps {
  isSelected?: boolean;
}

export const CategoryItem = styled.div<CategoryItemProps>`
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem;
  -webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.55);
  box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.55);

  padding: 1rem;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow: -moz-scrollbars-none;
  &::-webkit-scrollbar {
    display: none;
    width: 0 !important;
  }

  ${(props) =>
    props.isSelected &&
    css`
      border-color: #c72828;
      background-color: #ffebeb;
    `}

  img {
    height: 120px;
    width: 120px;
  }
  h2 {
    font-size: 1rem;
  }
`;
