import React, { useCallback, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { CircularProgress } from '@material-ui/core';
import { FiMail, FiLock, FiUser } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { BrowserView } from 'react-device-detect';
import { Container } from './style';
import logoImg from '../../assets/logo1.png';
import getValidationErrors from '../../utils/getValidationErrors';
import Input from '../../components/Input';
import Button from '../../components/Button';
import api from '../../services/api';
import Footer from '../../components/Footer';
import HeaderWeb from '../../components/HeaderWeb';
import { useToast } from '../../hooks/ToastContext';

interface SignUpFormData {
  name: string;
  email: string;
  password: string;
  passwordConfirmation: string;
}

const CreateAccount: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { addToast } = useToast();
  const [apiLoading, setApiLoading] = useState(false);

  const handleSignUp = useCallback(
    async (data: SignUpFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('O nome é obrigatório'),
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
          password: Yup.string().required('Senha obrigatória'),
          passwordConfirmation: Yup.string().oneOf(
            [Yup.ref('password'), undefined],
            'As senhas devem ser iguais'
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/users', {
          name: data.name,
          email: data.email,
          password: data.password,
        });
        setApiLoading(false);
        history.push('/signin');
      } catch (err) {
        setApiLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
        addToast({
          type: 'error',
          title: 'A conta já existe',
          description: err.response.data.message,
        });
      }
    },
    [addToast, history]
  );
  return (
    <>
      <BrowserView>
        <HeaderWeb />
      </BrowserView>
      <Container>
        <img src={logoImg} alt="Santa Carne Logo" className="logo" />
        <h1>Faça seu Cadastro</h1>
        <Form ref={formRef} onSubmit={handleSignUp}>
          <Input
            name="name"
            type="text"
            placeholder="Nome Completo"
            icon={FiUser}
          />
          <Input name="email" type="email" placeholder="E-mail" icon={FiMail} />
          <Input
            name="password"
            type="password"
            placeholder="Senha"
            icon={FiLock}
          />
          <Input
            name="passwordConfirmation"
            type="password"
            placeholder="Confirmar Senha"
            icon={FiLock}
          />
          <Button type="submit" loading={apiLoading}>
            Criar Conta
          </Button>
        </Form>
      </Container>
      <BrowserView style={{ marginTop: '1rem' }}>
        <Footer />
      </BrowserView>
    </>
  );
};
export default CreateAccount;
