import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import { FiAlertCircle } from 'react-icons/fi';
import { TextFieldProps } from '@material-ui/core';
import { Error, CustomTextField, ErrorContainer } from './styles';

const MaterialInput: React.FC<TextFieldProps> = ({
  name,
  type,
  label,
  ...rest
}) => {
  const inputRef = useRef<TextFieldProps>(null);
  const { fieldName, defaultValue, error, registerField } = useField(
    name || ''
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);
  return (
    <>
      <CustomTextField
        style={{ margin: '10px 0' }}
        variant="outlined"
        name={name}
        type={type}
        label={label}
        defaultValue={defaultValue}
        inputRef={inputRef}
        {...rest}
      />
      {error && (
        <Error title={error}>
          <ErrorContainer>
            <FiAlertCircle color="#c53030" size={20} />
            {error}
          </ErrorContainer>
        </Error>
      )}
    </>
  );
};
export default MaterialInput;
