import styled from 'styled-components';

export const StyledMenu = styled.nav<{ open: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: white;
  height: 100vh;
  text-align: left;
  padding: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};

  transition: transform 0.3s ease-in-out;

  .greattings {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.2rem;
  }

  @media (max-width: 680px) {
    width: 80%;
  }

  a {
    font-size: 1rem;
    text-transform: uppercase;
    padding: 1.2rem 0;
    font-weight: bold;
    color: black;
    text-decoration: none;
    transition: color 0.3s linear;
    border-bottom: 2px solid #ccc;
  }
`;
