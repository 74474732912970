import React from 'react';
import { IoMdPower } from 'react-icons/io';
import { Link, useHistory } from 'react-router-dom';
import { Container, NavBar } from './style';
import { useAuth } from '../../hooks/auth';
import LogoImg from '../../assets/whitesanta.png';

const HeaderWeb: React.FC = () => {
  const { signOut, user } = useAuth();
  const history = useHistory();
  return (
    <Container>
      <div className="logo">
        <img src={LogoImg} alt="Santa Carne" />
      </div>
      <NavBar>
        <div className="hi">{user && <p>Olá, {user.name}</p>}</div>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/store">Loja</Link>
          </li>
          <li>
            <Link to="/contact">Contato</Link>
          </li>
          <li>
            <Link to="/purchase">Meu Histórico</Link>
          </li>
          <li>
            {user ? (
              <button
                type="button"
                onClick={() => {
                  signOut();
                  history.push('/');
                }}
                style={{
                  background: 'transparent',
                  color: 'white',
                  cursor: 'pointer',
                }}
              >
                <IoMdPower />
              </button>
            ) : (
              <Link to="/signin" style={{ color: 'white' }}>
                Entrar
              </Link>
            )}
          </li>
        </ul>
      </NavBar>
    </Container>
  );
};
export default HeaderWeb;
