import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: 5%;
  width: 100%;
  .logo {
    width: 60%;
    flex-shrink: 1;
  }
  form {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
  }

  h1 {
    font-size: 24px;
    text-align: center;
    color: gray;
    margin: 24px 0 24px;
  }

  #create-account {
    align-self: flex-end;
    background: #ac0000;
    color: white;
    text-align: center;
    position: absolute;
    height: 3rem;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #forgot-password {
    margin: 12px 0;
    color: gray;
    text-align: center;
    font-size: 16px;
    flex: 1;
    margin-bottom: 4rem;
  }

  @media (min-width: 800px) {
    justify-content: center;
    width: 800px;
    width: unset;
    .logo {
      width: 250px;
    }
    #create-account {
      position: unset;
      align-self: center;
      width: 250px;
      margin-top: 2rem;
      border-radius: 5px;
    }
  }
`;
