import React, { useCallback, useEffect, useState } from 'react';
import { ImCancelCircle } from 'react-icons/im';
import { BrowserView } from 'react-device-detect';
import { Checkbox } from '@material-ui/core';
import Button from '../../components/Button';
import {
  ViewContentContainer,
  ViewContent,
  ViewProduct,
  ProductInformation,
  InformationDetails,
  ButtonsContainer,
  HistoricPurchaseContainer,
  Container,
  CurrentPurchaseContainer,
  CurrentPurchase,
  Conditions,
  HistoricPurchase,
  HistoryConditions,
} from './style';
import HeaderWeb from '../../components/HeaderWeb';
import Footer from '../../components/Footer';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/ToastContext';
import { Cart } from '../../interfaces/CartStructure';
import { useCart } from '../../hooks/cart';

interface FollowPurchase {
  id: string;
  inProduction: boolean;
  inDelivery: boolean;
  finished: boolean;
  cart: Cart;
  isPromoCart: boolean;
}

const Purchase: React.FC = () => {
  const { user } = useAuth();
  const [userOrders, setUserOrders] = useState<FollowPurchase[]>([]);
  const [oldUserOrders, setOldUserOrders] = useState<FollowPurchase[]>([]);
  const [newUserOrders, setNewUserOrders] = useState<FollowPurchase[]>([]);
  const [focusOrder, setFocusOrder] = useState<FollowPurchase>();
  const [historyShown, setHistoryShown] = useState(3);
  const [viewPurchase, setViewPurchase] = useState(false);
  const { addToast } = useToast();
  const { setNewCart } = useCart();

  useEffect(() => {
    try {
      api.get('/orders/listUserOrders/').then((response) => {
        setUserOrders(response.data);
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Não foi possível carregar sua lista de pedidos',
        description: error.response.data.message,
      });
    }
  }, [user.id, addToast]);

  useEffect(() => {
    setOldUserOrders(userOrders.filter((order) => order.finished === true));
    setNewUserOrders(userOrders.filter((order) => order.finished === false));
  }, [userOrders]);

  const historyShownChange = useCallback(() => {
    setHistoryShown(historyShown + 3);
  }, [historyShown]);

  const ViewPurchaseItems = useCallback((order: FollowPurchase) => {
    setFocusOrder(order);
    setViewPurchase(true);
  }, []);

  const handleAddToCart = useCallback(
    async (order: FollowPurchase) => {
      const cartToRepeat = order.cart.cartItems.map((item) => ({
        product: item.product,
        quantity: item.quantity,
        cut: item.cut || undefined,
      }));
      setNewCart(cartToRepeat);
    },
    [setNewCart]
  );

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const ButtonHandle = (finished: boolean) => {
    if (!finished) {
      return (
        <Button
          type="button"
          style={{ width: 240, display: 'none' }}
          onClick={() => {
            console.log('Gostaria de cancelar meu pedido');
          }}
        >
          <ImCancelCircle />
          Cancelar meu pedido
        </Button>
      );
    }
    return (
      <Button
        type="button"
        style={{ width: 240 }}
        onClick={() => {
          if (focusOrder) handleAddToCart(focusOrder);
        }}
      >
        Repetir pedido
      </Button>
    );
  };

  return (
    <>
      <BrowserView>
        <HeaderWeb />
      </BrowserView>
      {viewPurchase === true && focusOrder && (
        <ViewContentContainer>
          <h1>Meu pedido</h1>
          {focusOrder.cart.cartItems.map((item) => (
            <ViewContent key={item.id}>
              <ViewProduct>
                {item.product.name}
                <ProductInformation>
                  <img
                    src={item.product.imageUrl}
                    alt={item.product.name}
                    style={{ height: 150 }}
                  />
                  <InformationDetails>
                    <h1>Quantidade: {item.quantity}Kg</h1>
                    <h1>Tipo: {item.cut ? item.cut.name : 'Peça'}</h1>
                  </InformationDetails>
                </ProductInformation>
              </ViewProduct>
            </ViewContent>
          ))}
          <ButtonsContainer>
            <Button
              type="button"
              style={{ width: 240 }}
              onClick={() => {
                setViewPurchase(false);
              }}
            >
              Voltar para Meu Histórico
            </Button>
            {ButtonHandle(focusOrder.finished)}
          </ButtonsContainer>
        </ViewContentContainer>
      )}
      {viewPurchase === false && (
        <Container>
          <CurrentPurchaseContainer>
            <h1>Acompanhar Pedido</h1>
            {newUserOrders.length === 0 && (
              <CurrentPurchase>
                Você não possui nenhum pedido ativo
              </CurrentPurchase>
            )}
            {newUserOrders.map((order) => (
              <div key={order.id}>
                <CurrentPurchase>
                  <img
                    src={order.cart.cartItems[0].product.imageUrl}
                    alt={order.cart.cartItems[0].product.name}
                    style={{ height: 200 }}
                  />
                  <Conditions style={{ height: 200 }}>
                    <div>
                      <Checkbox
                        checked={order.inProduction}
                        readOnly
                        style={{ color: '#ac0000' }}
                      />
                      Pedido em produção
                    </div>
                    <div>
                      <Checkbox
                        checked={order.inDelivery}
                        readOnly
                        style={{ color: '#ac0000' }}
                      />
                      Pedido a caminho
                    </div>
                    <div>
                      <Checkbox
                        checked={order.finished}
                        readOnly
                        style={{ color: '#ac0000' }}
                      />
                      Pedido entregue
                    </div>
                    <Button
                      type="button"
                      onClick={() => {
                        ViewPurchaseItems(order);
                      }}
                    >
                      Visualizar meu pedido
                    </Button>
                  </Conditions>
                </CurrentPurchase>
              </div>
            ))}
          </CurrentPurchaseContainer>
          <HistoricPurchaseContainer>
            <h1>Histórico de Pedidos</h1>
            {oldUserOrders.length === 0 && (
              <HistoricPurchase style={{ marginBottom: 20 }}>
                Você não possui um histórico de pedidos
              </HistoricPurchase>
            )}
            {oldUserOrders.slice(0, historyShown).map((order) => (
              <div key={order.id}>
                <HistoricPurchase>
                  <img
                    src={order.cart.cartItems[0].product.imageUrl}
                    alt={order.cart.cartItems[0].product.name}
                    style={{ height: 100 }}
                  />
                  <HistoryConditions>
                    <Button
                      type="button"
                      onClick={() => {
                        ViewPurchaseItems(order);
                      }}
                    >
                      Visualizar pedido
                    </Button>
                  </HistoryConditions>
                </HistoricPurchase>
              </div>
            ))}
            {oldUserOrders.length !== 0 && (
              <Button
                type="button"
                onClick={() => {
                  historyShownChange();
                }}
              >
                Carregar Pedidos Anteriores
              </Button>
            )}
          </HistoricPurchaseContainer>
        </Container>
      )}
      <BrowserView style={{ marginTop: '1rem' }}>
        <Footer />
      </BrowserView>
    </>
  );
};

export default Purchase;
