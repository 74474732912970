import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr 1fr 1fr;
  grid-template-rows: 80px 240px auto 80px;

  grid-template-areas:
    'logo nav nav nav'
    'categories promo promo promo'
    'categories products detail cart'
    'footer footer footer footer';

  .logo {
    background: #ac0000;
    grid-area: logo;
    padding: 20px;
    img {
      width: 100%;
    }
  }

  .categories {
    grid-area: categories;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px #ac0000 solid;
  }

  .promos {
    grid-area: promo;

    ul {
      display: flex;
      overflow-x: auto;
      overflow-y: hidden;

      li {
        height: 200px;
        padding: 20px;

        img {
          height: 160px;
        }
      }
    }
  }

  .products {
    grid-area: products;
    padding: 1rem;
    .products-list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
      button {
        background: white;
        -webkit-box-shadow: 3px 3px 8px 1px rgba(0, 0, 0, 0.43);
        box-shadow: 3px 3px 8px 1px rgba(0, 0, 0, 0.43);
        padding: 1rem;
        img {
          width: 75px;
          height: 75px;
        }
      }
    }
  }

  .prod-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px;
    grid-area: detail;
    .prod-content {
      position: sticky;
      top: 0;
    }
    .inner-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: white;

      padding: 1rem;
      -webkit-box-shadow: 3px 3px 8px 1px rgba(0, 0, 0, 0.43);
      box-shadow: 3px 3px 8px 1px rgba(0, 0, 0, 0.43);
    }

    h3 {
      margin-top: 13px;
      font-size: 14px;
      color: #ac0000;
    }

    .first-section {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      width: 100%;
      gap: 1rem;
      .imagem {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      img {
        width: 150px;
        height: 150px;
      }
      .disclamer {
        font-size: 0.65rem;
      }
      .content {
        flex: 1;
        h1 {
          font-size: 20px;
          color: #ac0000;
        }
        .price {
          font-size: 12px;
          color: #59595f;
        }
        .increment-container {
          display: flex;
          align-items: center;
          justify-content: center;
          .product-amount {
            font-size: 12px;
            color: #59595f;
          }
        }
        .price-container {
          p {
            padding-left: 20px;
            font-size: 20px;
            margin: 0 15px;
          }
        }
        button {
          width: 30px;
          height: 30px;
          border-radius: 3px;
          background: rgba(232, 61, 91, 0.1);
          align-items: center;
          justify-content: center;
          color: #ac0000;
          margin: 0.5rem;
          svg {
            background: transparent;
          }
        }
      }
    }

    main {
      display: flex;
      flex-direction: column;
      align-items: center;

      .descrip {
        margin-left: 1rem;

        select {
          width: 80%;
        }
      }
      button {
        background: #ac0000;
        color: white;
        padding: 0.5rem;
        margin: 1rem auto;
        border-radius: 5px;
      }
    }
  }

  .cart {
    grid-area: cart;

    margin: 20px;
    height: 100%;
    .cart-content {
      position: sticky;
      top: 0;
    }
    ul {
      border-radius: 5px;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      overflow-y: auto;
      width: 100%;
      li {
        background: #fff;
        padding: 15px 10px;
        border-radius: 5px;
        margin: 5px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        img {
          height: 92px;
          width: 92px;
        }
        .info-container {
          font-size: 16px;
          margin-left: 5px;
          flex: 1;
          p {
            color: #ac0000;
          }
          .product-name {
            font-size: 16px;
            color: #000;
          }
          #info {
            font-size: 12px;
            color: #a0a0b3;
            margin-top: 8px;
          }
          .total-container {
            display: flex;
            p {
              font-weight: bold;
              margin-top: 5px;

              font-size: 16px;
              color: #e83f5b;
            }
            .quantity {
              margin-right: 10px;
            }
          }
        }
        .actionContainer {
          align-self: flex-end;
          display: flex;
          flex-direction: column;
          align-items: center;
          button {
            width: 30px;
            height: 30px;
            border-radius: 3px;
            background: rgba(232, 61, 91, 0.1);
            align-items: center;
            justify-content: center;
            color: #ac0000;
            margin: 0.5rem;
            svg {
              background: transparent;
            }
          }
        }
      }
    }
    .cart-total {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      color: #ac0000;

      h2 {
        font-size: 1rem;
        margin: 0 0.5rem;
      }
      p {
        color: #000;
        font-size: 1.3rem;
        margin: 0 0.5rem;
      }
    }
    button {
      width: 100%;
      padding: 0.5rem;
      color: white;
      margin-top: 1rem;
      background: #ac0000;
    }
  }

  .section-title {
    font-size: 1.3rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: #ac0000;
    text-align: left !important;
    align-self: start;
  }
`;

export const NavBar = styled.div`
  grid-area: nav;
  background: #ac0000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;

  ul {
    display: flex;
    align-items: center;
    color: white;
    li {
      padding: 20px;
      a {
        color: white;
      }
    }
  }
`;

interface CatProps {
  img: string;
  isSelected: boolean;
}

export const CategoryButton = styled.button<CatProps>`
  border-radius: 4px;
  -webkit-box-shadow: 3px 3px 8px 1px rgba(0, 0, 0, 0.43);
  box-shadow: 3px 3px 8px 1px rgba(0, 0, 0, 0.43);
  margin: 20px;
  width: 130px;
  height: 130px;
  background: url(${({ img }) => img});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  background-color: ${({ isSelected }) =>
    isSelected ? 'rgba(172,0,0,0.6)' : 'white'};
`;
